<template>
  <div class="s-layout" :style="colorObject">
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>
    <div class="s-container">
      <div>
        <Header
          :page-title="getModuleConfig('page_title')"
          :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')"
          :header-section="getModuleConfig('header_section')"
          :meta="meta"
        />

        <div v-if="page === 'scan'" class="page">
          <div class="card">
            <div class="card__title">{{ scanTitle }}</div>
            <div class="card__info">
              <div>
                <Loading v-if="isLoading" loading-message="載入中..." />

                <!-- Code manual input start -->
                <div>
                  <input
                    v-model="redeem_code"
                    class="search__input m-auto w-100"
                  />
                  <SharedButton class="s-btn-bg-primary mt-3" @click="manualInput">
                    送出
                  </SharedButton>
                </div>
                <!-- Code manual input end -->
              </div>
            </div>
          </div>
        </div>

        <!-- page: redeem start -->
        <div v-if="page === 'redeem'" class="page">
          <!-- Card Start -->
          <div class="card">
            <div class="redeem">
              <span class="redeem__code">{{ redeem_code }}</span>
            </div>
            <div class="card__divider"></div>
            <div class="card__info">
              <!-- 兌換說明欄位 Start -->
              <template v-if="event">
                <div class="card__title">{{ confirmTitle }}</div>
                <div v-html="confirmMessage"></div>
              </template>
              <!-- 兌換說明欄位 End -->
            </div>
          </div>
          <!-- Card End -->
          <!-- Buttons Start -->
          <div class="btn">
            <button
                class="s-btn s-btn-bg-primary s-btn-xs s-mb-3"
                type="button"
                @click="redeem()"
                :disabled="isLoading"
            >
                {{ confirmButtonText }}
            </button>
            <button
              class="s-btn s-btn-outline-primary s-btn-xs"
              type="button"
              @click="backToScan()"
              :disabled="isLoading"
            >
              輸入
            </button>
          </div>
          <!-- Buttons End -->
        </div>
        <!-- page: redeem End -->

        <!-- page: status -->
        <div v-if="page === 'status'" class="page">
          <div class="card">
            <div class="card__info">
              <div class="card__title">
                {{ statusTitle }}
              </div>
              <div v-html="statusMessage"></div>
            </div>
          </div>

          <div class="btn">
            <MemberButton
                :button-text="cancelButtonText"
                :button-url="cancelButtonUrl"
                :type="cancelButtonAction"
            ></MemberButton>

            <button
                class="s-btn s-btn-outline-primary s-btn-xs s-mt-3"
                type="button"
                @click="backToScan()"
                :disabled="isLoading"
            >
                {{ rescanButtonText }}
            </button>
          </div>
        </div>
        <!-- page: status -->
      </div>
    </div>
  </div>
</template>

<script>
// Packages
import { mapGetters } from "vuex";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
// import _ from "lodash";
// Waltily Utils
import { themeColorFn } from "@/mixins/liff/themeColor";
import redeemApi from "@/apis/liff/v2/redeem";
import dateOptionMixin from "@/mixins/liff/dateOption";
import https from "@/apis/liff/v2/https";
// Components
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import liff from "@line/liff";

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'redeem' }),
    dateOptionMixin,
  ],
  components: {
    Header,
    Loading,
    SharedButton,
    MemberButton,
  },
  data() {
    return {
      isLoading: false,
      event_code: null,
      redeem_code: null,
      event: {
        config: {},
      },
      page: 'redeem', // scan, redeem, status
      apiResultMessage: null,
      showQrCodeScanner: true,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
    confirmTitle() {
      return this.event.config['confirm_title'] ?? '確認兌換';
    },
    confirmMessage() {
      return this.event.config['confirm_message'] ?? "請確認是否集點";
    },
    confirmButtonText() {
      return this.event.config['confirm_button_text'] ?? '確認';
    },
    scanTitle() {
      if (this.event.config['scan_title']) {
        return this.event.config['scan_title'];
      }

      return "手動輸入序號";
    },
    rescanButtonText() {
      return this.event.config['rescan_button_text'] ?? '重新掃描或輸入';
    },
    cancelButtonText() {
      return this.event.config['cancel_button_text'] ?? '返回 LINE';
    },
    cancelButtonUrl() {
      return this.event.config['cancel_button_url'] ?? '';
    },
    cancelButtonAction() {
      return this.event.config['cancel_button_action'] ?? 'CLOSE';
    },
    statusTitle() {
      switch (this.apiResultMessage) {
        case "EVENT_NOT_FOUND":
        case "EVENT_EXPIRED":
          return this.event.config['event_expired_title'] ?? '活動過期';
        case "REDEEM_CODE_NOT_FOUND":
          return this.event.config['redeem_code_not_found_title'] ?? '找不到序號';
        case "REDEEM_CODE_ALREADY_USED":
          return this.event.config['redeem_code_already_used_title'] ?? '已使用過';
        case "REDEEM_SUCCESS":
          return this.event.config['redeem_success_title'] ?? '兌換成功';
        case "RECLAIM_LIMIT":
          return this.event.config['reclaim_limit_title'] ?? '兌換次數已達上限';
        default:
          return "兌換失敗";
      }
    },
    statusMessage() {
      switch (this.apiResultMessage) {
        case "EVENT_NOT_FOUND":
        case "EVENT_EXPIRED":
          return this.event.config['event_expired_message'] ?? '活動過期';
        case "REDEEM_CODE_NOT_FOUND":
          return this.event.config['redeem_code_not_found_message'] ?? '找不到序號';
        case "REDEEM_CODE_ALREADY_USED":
          return this.event.config['redeem_code_already_used_message'] ?? '已使用過';
        case "REDEEM_SUCCESS":
          return this.event.config['redeem_success_message'] ?? '兌換成功';
        case "RECLAIM_LIMIT":
          return this.event.config['reclaim_limit_message'] ?? '兌換次數已達上限';
        default:
          return "兌換失敗";
      }
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.redeem_code = this.$route.query.redeem_code ?? null;
      this.event_code = this.$route.query.event_code ?? null;

      this.fetchEvent();

      if (!this.redeem_code) {
        this.page = "scan";
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("liff_redeem", key);
    },
    backToScan() {
      this.page = "scan";
      this.showQrCodeScanner = true;
    },
    manualInput() {
      this.page = "redeem";
    },
    async fetchEvent() {
      try {
        this.isLoading = true;

        const response = await redeemApi.getRedeemEvent({
          event_code: this.event_code,
        });

        this.event = response.data.data.event;
      } catch (error) {
        console.error(error);
        this.$swal('錯誤', '活動資訊讀取失敗', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async redeem() {
      try {
        if (! this.redeem_code || ! this.event_code) {
          this.$swal("錯誤", "你使用的網址錯誤，請重新確認", "warning");
          // TODO: 這裡其實可以直接導去掃碼輸入頁面
          return;
        }

        this.isLoading = true;

        let response = await https.post(`${this.$store.state.liffGeneral.orgCode}/liff/clinique/redeem`, {
          redeem_code: this.redeem_code,
          event_code: this.event_code.toLowerCase(),
        });

        if (response.data.message === "REDEEM_SUCCESS") {
          this.page = "status";
          this.apiResultMessage = "REDEEM_SUCCESS"
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.message) {
          this.page = "status";
          this.apiResultMessage = error.response.data.message;
          return;
        }

        this.$swal("錯誤", "兌換發生錯誤，請稍後再試", "error");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      liff.closeWindow();
    },
  }
}
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import '../../../assets/scss/shared/components/_fields.scss';

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
  position: relative;
  max-width: 768px;
  margin: auto;
}

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.content {
  height: calc(100vh - 56px);
  overflow-y: auto;
  margin: 0 24px;
  padding-bottom: 24px;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

.logo {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;

  &__title {
    color: #2c2c2e;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__divider {
    border-bottom: 2px dashed #e5e5ea;
  }
  &__info {
    color: #636366;
    line-height: 24px;
    font-size: 16px;
    padding-top: 12px;
    b {
      color: #2c2c2e;
      font-weight: 500;
    }
  }
}


.redeem {
  background: url("./images/bg_redeem.png");
  background-size: cover;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  &__code {
    color: #2c2c2e;
    font-size: 19px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #636366;
    border-radius: 10px !important;
    border: 1px solid #636366;
    padding: 15px 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 32px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }
  }
}

.btn {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  .s-btn-bg-primary {
      background: var(--liff-button-color);
      color: var(--liff-button-text-color);
  }

  .s-btn-outline-primary,
  .s-btn-outline-primary:focus,
  .s-btn-outline-primary:active {
    background: transparent;
    border: 1px solid var(--liff-button-color);
    color: var(--liff-button-color);
  }
}

.gift__image {
  width: 100%;
  object-fit: contain;
}
.loading-spinner {
  order: 4;
}


.custom-checkbox {
  padding-left: 1.5rem;
  margin-right: 1rem;

  .custom-control-input {
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 16px;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color) !important;
      border-radius: 4px;
      background: white;
      left: -1.5rem;
    }

    & + label::after {
      left: -1.5rem;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before,
    &:active + label:before {
      background: var(--liff-button-color) !important;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: var(--s-gray-light);
    }
  }
}

.fixed-footer{
  position:sticky;
  bottom: 0;
  z-index: 1;
}

.field__label {
  font-size: 16px !important;
}

.field__desc {
  font-size: 14px !important;
}

.search {
  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}

.scan-content {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

.scan-filter {
  display: flex;
  justify-content: center;
}

</style>
